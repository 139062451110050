<!--
 * @Description: 
 * @Author: huacong
 * @Date: 2021-04-01 18:59:33
 * @LastEditTime: 2021-09-02 17:00:30
 * @LastEditors: huacong
-->
<template>
  <!-- 微信绑定 -->
  <div class="talentBg">
    <section>
      <el-row class="talentIndex" :gutter="16">
        <el-col :span="8">
          <talentInfo></talentInfo>
        </el-col>
        <el-col :span="16">
          <div class="tabBox" style="position:relative">
            <div class="tabTitle">
              微信绑定
            </div>
            <div id="codeBox" class="text-center"></div>
            <div class="bandMark" v-show="wxData">
              <img :src="bandMark" alt="" />
            </div>
            <div class="formBox text-center">
              <p style="color: #666;" v-if="!wxData">
                请使用微信扫描二维码登录“建搜搜”
              </p>
              <p style="color: #666;" v-if="wxData">
                已绑定微信：微信昵称
                <span style="color:#333"> {{ userInfo.u_nickname }}</span>
              </p>
              <p style="color: #666;">手机号码 {{ userInfo.u_phone }}</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </section>
  </div>
</template>
<script src="http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
import talentInfo from "@/view/talentPage/talentInfo.vue";
import { mapState } from "vuex";
export default {
  components: {
    talentInfo,
  },
  data() {
    return {
      bandMark: require("@/assets/img/bandMark.png"),
      wxData: false,
    };
  },
  computed: {
    ...mapState(["userInfo", "wxName"]),
    userInfo() {
      let k = localStorage.getItem("userInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  methods: {
    setWxerwma() {
      sessionStorage.setItem("loginer", "1");
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
      const wxElement = document.body.appendChild(s);
      wxElement.onload = function() {
        var obj = new WxLogin({
          id: "codeBox", // 需要显示的容器id
          appid: "wxa8ea41b6aba67301", // 公众号appid
          scope: "snsapi_login", // 网页默认即可
          redirect_uri: "http%3A%2F%2Fwww.51jiansousou.com", // 授权成功后回调的url  51jiansousou.com   zhaomy-frp.4kb.cn
          state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
          style: "", // 提供"black"、"white"可选。二维码的样式
          href: "", // 外部css文件url，需要https
        });
      };
    },
    findWxData() {
      console.log(this.wxName);
      console.log(this.userInfo.u_nickname);
      if (this.userInfo.u_nickname || this.wxName) {
        this.wxData = true;
      } else {
        this.wxData = false;
      }
    },
  },
  mounted() {
    this.setWxerwma();
    this.findWxData();
  },
};
</script>
<style scoped>
.talentIndex {
  padding-top: 24px;
}
.tabTitle {
  font-size: 28px;
  color: #2573f1;
  font-weight: bold;
  text-align: center;
  margin: 30px 0;
}
.tabTitle::before {
  content: "";
  display: inline-block;
  width: 73px;
  height: 3px;
  background-image: url("../../assets/img/lineLeft.png");
  margin-right: 24px;
  position: relative;
  top: -7px;
}
.tabTitle::after {
  content: "";
  display: inline-block;
  width: 73px;
  height: 3px;
  background-image: url("../../assets/img/lineRight.png");
  margin-left: 24px;
  position: relative;
  top: -7px;
}
.title {
  opacity: 0;
}
.formBox {
  width: 500px;
  margin: 0 auto;
}
.successBox {
  text-align: center;
}
.successBox p {
  color: #2573f1;
}
.successBox button {
  margin: 24px;
}
.bandMark {
  width: 282px;
  height: 282px;
  text-align: center;
  position: absolute;
  top: 114px;
  left: 256px;
  background-color: rgba(0, 0, 0, 0.2);
}
.bandMark img {
  width: 95%;
  margin-top: 35px;
}
</style>
